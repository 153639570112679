
export const homeObj1 = {
    id: 'about',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'About Us',
    headline: 'PZMOO is a Washington DC-based digital company providing comprehensive technology solutions, including web, mobile and MVP application development services.',
    description: 'Founded in 2018, we are a technology-driven company passionate about providing innovative solutions to our clients. We aim to become the go-to provider of cutting edge technologies and superior customer experiences.',
    buttonLabel: 'Services',
    imgStart: false,
    img: require('../../Images/about.gif').default,
    alt: 'Car',
    dark: true,
    primary: true,
    darkText: false
}
export const homeObj2 = {
    id: 'discover',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Unlimited Access',
    headline: 'Login your account at any time',
    description: 'We have you covered no matter where you are located. All you need is internet connection and a phone.',
    buttonLabel: 'Learn More',
    imgStart: true,
    img: require('../../Images/Ventera.png').default,
    img1: require('../../Images/cms.png').default,
    img2: require('../../Images/uscis.png').default,
    img3: require('../../Images/ssa.png').default,
    img4: require('../../Images/drupal.jpeg').default,
    img5: require('../../Images/logo_tole.png').default,

    alt: 'Piggybank',
    dark: false,
    primary: false,
    darkText: true
}
export const homeObj3 = {
    id: 'contact',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Contact Us',
    headline: 'We Are Here To Help!',
    description: 'Let us know how we can assist you with any questions, comments, or concerns you may have about our services. We look forward to hearing from you!',
    buttonLabel: 'Start Now',
    imgStart: false,
    img: require('../../Images/Account2.svg').default,
    alt: 'Paper',
    dark: false,
    primary: false,
    darkText: true
}