import React from 'react'
import {
    FooterContainer,
    FooterLink,
    FooterLinkContainer,
    FooterLinkItems,
    FooterLinkTitle,
    FooterLinkWrapper,
    FooterWrap,
    SocialIconLink,
    SocialIcons,
    SocialLogo,
    SocialMedia,
    SocialMediaWrap,
    WebsiteRights,
    ImgWrapLogo,
    Img,
    NavLinks,
    Nacis
} from './FooterElements'
import {
    FaFacebook,
    FaInstagram,
    FaTwitter,
    FaYoutube
} from 'react-icons/fa'
import { animateScroll as scroll } from 'react-scroll';

const Footer = () => {
    let logo = require("../../Images/logo.png").default


    const toggleHome = () => {
        scroll.scrollToTop()
    }

    return (
        <>
            <FooterContainer>
                <FooterWrap>
                    <FooterLinkContainer>
                        <FooterLinkWrapper>
                            <FooterLinkItems>
                                <FooterLinkTitle>About Us</FooterLinkTitle>
                                <NavLinks
                                    to="services"
                                    smooth={false}
                                    duration={500}
                                    spy={false}
                                    exact='true'
                                    offset={-80}
                                >Services</NavLinks>
                                <NavLinks
                                    to="about"
                                    smooth={false}
                                    duration={500}
                                    spy={false}
                                    exact='true'
                                    offset={-80}
                                >About </NavLinks>
                            </FooterLinkItems>
                        </FooterLinkWrapper>
                        <FooterLinkWrapper>
                            <FooterLinkItems>
                                <FooterLinkTitle>Contact Us</FooterLinkTitle>
                                <NavLinks
                                    to="contact"
                                    smooth={false}
                                    duration={500}
                                    spy={false}
                                    exact='true'
                                    offset={-80}
                                >Contact</NavLinks>

                                {/* <FooterLink to="/careers">careers</FooterLink> */}
                            </FooterLinkItems>
                        </FooterLinkWrapper>
                    </FooterLinkContainer>
                    <SocialMedia>
                        <SocialMediaWrap>
                            <SocialLogo to="/" onClick={toggleHome}>
                                <ImgWrapLogo>
                                    <Img src={logo} alt="PZMOO LLC" />
                                </ImgWrapLogo>
                            </SocialLogo>
                            <WebsiteRights>{new Date().getFullYear()} All rights reserved.
                            </WebsiteRights>
                            <SocialIcons>
                                <Nacis>NAICS codes: 54151, 541512, 541513, 541519, 541611, 541618, 541690, 541715, 541990 </Nacis>
                            </SocialIcons>
                        </SocialMediaWrap>
                    </SocialMedia>
                </FooterWrap>
            </FooterContainer>
        </>
    )
}

export default Footer
