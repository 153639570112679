import React from 'react'
import {
    ServicesContainer,
    ServicesCard,
    ServicesH1,
    ServicesH2,
    ServicesP,
    ServicesWrapper,
    ServicesIcon
} from './ServiceElements'
import Icon1 from '../../Images/img1.png'
import Icon2 from '../../Images/img2.png'
import Icon3 from '../../Images/img3.png'
import Icon4 from '../../Images/img4.png'
import Icon5 from '../../Images/img5.png'
import Icon6 from '../../Images/img6.png'


const Services = () => {
    return (
        <>
            <ServicesContainer id="services">
                <ServicesH1>Our Services</ServicesH1>
                <ServicesWrapper>
                    <ServicesCard>
                        <ServicesIcon src={Icon1} />
                        <ServicesH2>IT Modernization</ServicesH2>
                        <ServicesP>
                            We provide comprehensive infrastructure assessment
                            services to help our clients identify the optimal,
                            cost efficient, reliable, scalable, and high performing
                            solutions for their business needs.
                        </ServicesP>
                    </ServicesCard>
                    <ServicesCard>
                        <ServicesIcon src={Icon2} />
                        <ServicesH2>Custom software Development</ServicesH2>
                        <ServicesP>
                            We develop custom software solutions utilizing the latest
                            technologies to meet unique business needs.
                            Our software solutions are secure, scalable, and resilient,
                            offering a tailored service with personalized attention.
                        </ServicesP>
                    </ServicesCard>
                    <ServicesCard>
                        <ServicesIcon src={Icon3} />
                        <ServicesH2>Startup MVP Development</ServicesH2>
                        <ServicesP>
                            We assist startups in quickly creating MVPs.
                            Our team utilizes agile development
                            methods and proven tools including ReactJS,
                            React Native, NodeJS and SpringBoot to bring clients'
                            ideas to life.
                        </ServicesP>
                    </ServicesCard>
                    <ServicesCard>
                        <ServicesIcon src={Icon4} />
                        <ServicesH2>Cloud and Infrastructure Services</ServicesH2>
                        <ServicesP>
                            we provide cloud and infrastructure services to businesses, enabling
                            them to manage their resources with efficiency and cost savings.
                            We specialize in innovative solutions such as cloud hosting,
                            virtualization, storage, and disaster recovery.
                        </ServicesP>
                    </ServicesCard>
                    <ServicesCard>
                        <ServicesIcon src={Icon5} />
                        <ServicesH2>AI/ML And Data Science </ServicesH2>
                        <ServicesP>
                            Our team specializes in AI and ML technologies and data science
                            services that provide businesses with actionable insights and
                            predictive models for a wide range of applications. We customize
                            our solutions to fit each customer's unique needs.
                        </ServicesP>
                    </ServicesCard>
                    <ServicesCard>
                        <ServicesIcon src={Icon6} />
                        <ServicesH2>Business Analysis</ServicesH2>
                        <ServicesP>
                            We offer tailored solutions to organizations of all sizes and industries,
                            to improve performance, reduce costs & optimize customer experience.
                            Our services include process mapping, metrics development, operational &
                            technology assessments, and process improvement.
                        </ServicesP>
                    </ServicesCard>
                </ServicesWrapper>
            </ServicesContainer>
        </>
    )
}

export default Services


