import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

import {
    InfoContainer,
    InfoWrapper,
    InfoRow,
    BtnWrap,
    Column1,
    Column2,
    Heading,
    ImgWrap,
    Subtitle,
    TextWrapper,
    TopLine,
    Img,
    ImgWrapLogo
} from '../Info/InfoElements'


import { Button } from '../../Utilities/ButtonElements'

const InfoSection = ({
    lightBg,
    imgStart,
    topLine,
    lightText,
    darkText,
    headline,
    description,
    buttonLabel,
    alt,
    img,
    img1,
    img2,
    img3,
    img4,
    img5,
    id,
    primary,
    dark,
    dark2
}) => {
    const useStyles = makeStyles((theme) => ({
        root: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
            flexWrap: 'wrap',
        },
        box: {
            width: '200px',
            height: '0px',
            padding: '40px',
            textAlign: 'center',
        },
    }));

    const classes = useStyles();

    return (
        <>
            <InfoContainer lightBg={lightBg} id={id}>
                <InfoWrapper>
                    <div  style={{  opacity: 0.2 }} className={classes.root}>
                        <Box className={classes.box}>
                            <ImgWrapLogo>
                                <Img src={img5} alt={alt} />
                                <p style={{marginTop: "-25px", fontSize:"12px"}}> ToleAI</p>
                            </ImgWrapLogo>
                        </Box>
                        <Box className={classes.box}>
                            <ImgWrapLogo>
                                <Img src={img} alt={alt} style={{marginTop:"20px"}} />
                            </ImgWrapLogo>
                        </Box>
                        <Box className={classes.box}>
                            <ImgWrapLogo>
                            <Img src={img4} alt={alt} style={{marginTop:"20px"}} />
                            </ImgWrapLogo>
                        </Box>
                        <Box className={classes.box}>
                            <ImgWrapLogo>
                            <Img src={img3} alt={alt} style={{marginTop:"10px"}} />

                            </ImgWrapLogo>
                        </Box>
                        <Box className={classes.box}>
                            <ImgWrapLogo>
                            <Img src={img1} alt={alt} style={{marginTop:"20px"}} />
                            </ImgWrapLogo>
                        </Box>
                    </div>
                </InfoWrapper>
            </InfoContainer>
        </>
    )
}

export default InfoSection
